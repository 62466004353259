.lista_hover {
    background-color: transparent;
}

.lista_hover:hover {
    background-color: azure;
}

@media screen and (max-width: 992px){
    .lista_item {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
        border-radius: 20px;
        border-left: 6px var(--verde) solid !important;
    }
}