/*
  * Custom styling for fonts
*/

.fw-black {
    font-weight: 900;
}

.fw-500 {
    font-weight: 500;
}