.nav_item {
    /* font-size: 14px; */
}

.nav_item,
.nav_item:hover {
    text-decoration: none;
    color: inherit;    
}

.nav_item > div {
    background-color: transparent;
    transition: all ease .1s;
}
.nav_item.nav_item_active > div,
.nav_item > div:hover {
    background-color: var(--nav_item_hover);
    color: var(--vermelho) !important;
}