.search_list {
  position: relative;
}

.search_list input {
  padding: 0.7rem;
  padding-right: 35px;
}

.cancel {
  position: absolute;
  font-size: 1.1em;
  right: 13px;
  color: var(--bs-gray-600);
  top: calc(0.7rem - 3px);
  cursor: pointer;
  padding: 3px;
}
