.photos {

}

.photos img {
    border-radius: .75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    max-width: 300px;
    max-height: 300px;
}

.photos_placeholder {
    height: 150px;
    width: 200px;
    border: 1px solid var(--bs-gray-400);
    border-radius: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    background: white;
    color: var(--verde);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}

@media screen and (max-width: 992px) {
    .photos_placeholder {
        width: 100%;
    }
}