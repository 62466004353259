.box {
    max-width: 500px;
    width: 100%;
    background-color: var(--verde);
    background-color: white;
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: center;
}

.logo_menu {
    max-width: 200px;
}

@media screen and (max-width: 992px) {
    .box {
        border: 0 !important;
        box-shadow: none !important;
    }
}