.nav {
    background-color: var(--nav_bg);
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.logo_menu {
    max-width: 200px;
}

.logo_mobile {
    height: 60px;
}

@media screen and (min-width: 993px){
    .nav {
        display: flex;
        flex-direction: column;
    }

    .nav_buttons {
        flex-grow: 1;
    }

    .nav.hidden {
        width: 65px;
        overflow-x: hidden;
        text-align: center !important;
    }
    
    .nav.hidden :global(.nav_hide_none) {display: none;}
    .nav.hidden :global(.nav_hide_block) {display: block !important;}

    .nav_toggle {text-align: right;}
    .nav.hidden .nav_toggle {text-align: center;}

    .nav.hidden .nav_hide_button {display: none;}
    .nav:not(.hidden) .nav_show_button {display: none;}
}

@media screen and (max-width: 992px) {
    .nav {
        width: 100%;
        transform: translateX(100%);
        border-radius: 0 !important;
        transition: transform ease .5s;
        -webkit-transition: transform ease .5s;
    }
    .nav_show {
        transform: translateX(0);
    }
}