.form-group {
    margin-bottom: 2rem;
}

.form-group label {
    font-weight: bold;
    margin-bottom: .5rem;
}

textarea.form-control {
    min-height: 100px;
}

input.hidden {
    width: 0;
    height: 0;
    font-size: 0;
    opacity: 0;
    position: absolute;
    z-index: -99999;
    top: 0;
    left: 0;
}