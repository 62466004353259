.box-widget {
    background-color: white;
    border-radius: 1rem;
    padding: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.box-widget-table {
    background-color: white;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.box-widget-table .table {
    margin-bottom: 0 !important;
}

.box-widget-table .table tbody {
    border-top: 0 !important;
}

@media screen and (max-width: 992px) {
    .box-widget {
        padding: 2rem;
    }
}

.box-shadow-hover {
    transition: all ease .3s;
    box-shadow: none;
}

.box-shadow-hover:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}