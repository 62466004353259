.photo {
    border: 1px solid var(--bs-gray-400);
    border-radius: .75rem;
    text-align: center;
}

.photo img {
    max-width: 100%;
    max-height: 200px;
    border-radius: .75rem;
}

.photo .placeholder {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    background: white;
    color: var(--verde);
}

.photo_min img {
    max-width: 120px;
}

@media screen and (max-width: 992px) {
    .photo .placeholder {
        width: 100%;
    }
}