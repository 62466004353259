.nav_outer {
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
}

.nav_tab {
  white-space: nowrap;
}

.nav_tab .nav_link {
  display: inline-block;
  padding-bottom: 0.5rem;
  color: unset;
  margin-right: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  opacity: 0.6;
  transition: opacity ease 0.3s;
}

.nav_tab .nav_link:hover {
  opacity: 1;
}

.nav_tab .nav_link:global(.active) {
  opacity: 1;
  font-weight: bold;
  color: var(--verde);
  position: relative;
}

.nav_tab .nav_link:global(.active)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--verde);
  border-radius: 8px;
}

@media screen and (max-width: 992px) {
  .nav_outer {
    overflow-x: scroll;
  }
  .nav_tab {
    text-align: center;
  }
  .nav_tab .nav_link {
    font-size: 0.8em;

    margin: 0 0.75rem;
  }
}
