:root {
    --verde: #1a934a;
    --vermelho: #e0162f;
    --nav_bg: #00643a;
    --nav_item_hover: var(--bs-light);
}

.bg-nav {
    background-color: var(--nav_bg);
}

.bg-indigo {
    background-color: #7300ff;
}

.bg-indigo-hover:active,
.bg-indigo-hover:focus,
.bg-indigo-hover:hover {
    background-color: #5400bb !important;
}