@media screen and (min-width: 993px){
    .columns {
        min-height: 500px;
    }
}

.card {
    animation: showCard .3s ease forwards;
    opacity: 0;
    transform: translateX(-10px);
}

@keyframes showCard {
    to {opacity: 1; transform: translateX(0); }
}

.hidden {
    width: 0;
    height: 0;
    font-size: 0;
    opacity: 0;
    position: absolute;
    z-index: -99999;
    top: 0;
    left: 0;
}