.COMPLETED {
    background-color: var(--bs-light);
    color: var(--bs-secondary);
}

.DELIVERING {
    background-color: var(--bs-primary);
    color: white;
}

.WAITING_DELIVER {
    background-color: rgba(var(--bs-primary-rgb), .6);
    color: white;
}

.PAID {
    background-color: var(--bs-success);
    color: white;
}

.PREPARING {
    background-color: #7300ff;
    color: white;    
}

.PROCESSING {
    background-color: var(--bs-light);
    color: var(--bs-warning);
}

.ANALYZING {
    background-color: var(--bs-warning);
}

.CANCELED {
    background-color: var(--bs-danger);
    color: white;
}

.presencial {
    background-color: rgba(var(--bs-info-rgb),.3);
}

.entregar {
    background-color: var(--bs-info);
}