.outlet {
    z-index: 1;
  }
  
  @media screen and (min-width: 993px) {
    .outlet {
      padding-left: 250px;
    }

    :global(body.nav_hidden) .outlet {
        padding-left: 65px;
    }
  }
  