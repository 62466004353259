html,
body {
  width: 100% !important;
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  background-color: var(--bs-light);
}

*,
*:focus,
*:hover {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.container-limited {
  max-width: 1200px;
}

.link,
.link:hover {
    text-decoration: none;
}

@media screen and (min-width: 993px){
    html,
    body {
      font-size: 14px;
    }    
}