.loading_shield {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: var(--nav_bg);
}

.loading_shield.hide {
    animation: hideLoading .5s ease forwards;
    -webkit-animation: hideLoading .5s ease forwards;
}

@keyframes hideLoading {
    0% {
        opacity: 1;
    }
    99.9% {
        opacity: 0;
        transform: scale(1);
        z-index: 100;
    }
    100% {
        opacity: 0;
        transform: scale(0);
        z-index: -100;
    }
}

.loading_icon {
    display: inline-block;
    border-radius: 100%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.123);
    background: white;
}

.loading_icon img {
    height: 2em;
    animation: spinningLoading 3s cubic-bezier(0.57, 0.08, 0.44, 0.95) infinite alternate;
    -webkit-animation: spinningLoading 3s cubic-bezier(0.57, 0.08, 0.44, 0.95) infinite alternate;
}

@keyframes spinningLoading {
    to {
        transform: rotate(-1080deg);
    }
}
@-webkit-keyframes spinningLoading {
    to {
        transform: rotate(-1080deg);
    }
}

.loading_text {
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
}