.m-n-content {
    margin-left: -3rem;
    margin-right: -3rem;
}

@media screen and (max-width: 992px){
    .m-n-content,
    .m-n-content-mobile {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
}
