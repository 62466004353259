.subgrupo {
  background-color: var(--bs-gray-300);
}

@media screen and (min-width: 997px) {
  .subgrupos {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .subgrupos:hover {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

@media screen and (max-width: 996px) {
  .box {
    margin: 0 -2rem;
    border: none !important;
  }
}
