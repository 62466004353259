/*
  * Global custom classes for font-sizes   
*/

.fsc-1 {font-size: 1em;}
.fsc-1p2 {font-size: 1.2em;}
.fsc-1p5 {font-size: 1.5em;}
.fsc-2 {font-size: 2em;}
.fsc-2p5 {font-size: 2.5em;}
.fsc-3 {font-size: 3em;}
.fsc-4 {font-size: 4em;}
.fsc-5 {font-size: 5em;}

.fsc-n9 {font-size: .9em;}
.fsc-n8 {font-size: .8em;}
.fsc-n7 {font-size: .7em;}
.fsc-n6 {font-size: .6em;}
.fsc-n5 {font-size: .5em;}

@media screen and (max-width: 768px) {
  .fsc-m-1 {font-size: 1em !important;}
  .fsc-m-1p2 {font-size: 1.2em !important;}
  .fsc-m-1p5 {font-size: 1.5em !important;}
  .fsc-m-2 {font-size: 2em !important;}
  .fsc-m-2p5 {font-size: 2.5em !important;}
  .fsc-m-3 {font-size: 3em !important;}
  .fsc-m-5 {font-size: 5em !important;}

  .fsc-m-n9 {font-size: .9em !important;}
  .fsc-m-n8 {font-size: .8em !important;}
  .fsc-m-n7 {font-size: .7em !important;}
  .fsc-m-n6 {font-size: .6em !important;}
  .fsc-m-n5 {font-size: .5em !important;}
}