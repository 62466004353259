.modal {
}

.hidden {
    font-size: 0;
    width: 0;
    height: 0;
}

@media screen and (max-width: 992px) {
  .modal_dialog {
    margin: 0 !important;
    align-items: end !important;
    max-height: unset;
    height: 100% !important;
    max-width: 100% !important;
  }

  .modal_content {
    border: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .modal:global(.fade) .modal_dialog {
    transform: translate(0, 50px);
  }
  .modal:global(.show) .modal_dialog {
    transform: none;
  }
  .modal :global(.modal-body) {
    max-height: 80vh;
  }
}
